@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #111111;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  animation: fadeInAnime 1.5s ease 0s;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  max-width: 1642px;
  margin: auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 150px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 0;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

#g-nav.panelactive .fix_contact {
  margin-top: 30px;
}

.navR {
  display: flex;
  flex-direction: column-reverse;
}

#g-nav {
  position: relative;
}

@media only screen and (max-width: 900px) {
  #g-nav {
    display: flex;
  }
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  margin: auto;
  flex-wrap: wrap;
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    width: 80%;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px;
  display: block;
  position: relative;
  color: #111111;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  #g-nav .mainNav li a {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.8rem;
    border-bottom: 1px solid #e07ca74a;
    text-align: left;
    position: relative;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    color: #84004f;
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #ff33a0;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #FCF7FB;
    color: #111111 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  background: url(../images/home/hm_bg.png) center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10000;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6rem;
}

.header .h-logo {
  text-align: left;
}

@media only screen and (max-width: 900px) {
  .header .h-logo {
    width: 30%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .header {
    padding: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    padding: 24px;
  }
}

.header {
  z-index: 9997;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

@media only screen and (max-width: 900px) {
  .header {
    box-shadow: 0 0 14px rgba(204, 204, 204, 0.4);
  }
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.header--pinned {
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

.h_contact {
  position: relative;
  width: 380px;
  height: 139px;
  margin-top: -40px;
  margin-left: auto;
}

.h_contact a {
  display: block;
}

.h_contact .mail {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.h_contact .insta {
  position: absolute;
  right: 0;
}

.h_contact .tel {
  position: absolute;
  left: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .h_contact {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .h_contact {
    display: none;
  }
}

.fix_contact {
  display: none;
  z-index: 2;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .fix_contact {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

@media only screen and (max-width: 900px) {
  .fix_contact {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.mainWrap {
  position: relative;
}

.mainWrap .mainText {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 834px) {
  .mainWrap .mainText {
    display: flex;
    transform: none;
    top: unset;
    bottom: 2%;
    right: 5%;
  }
}

.mainWrap .mainText h1 {
  font-size: clamp(26px, 4vw, 44px);
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  writing-mode: vertical-rl;
  line-height: 1.25em;
}

.mainWrap .mainText h1 .ja01, .mainWrap .mainText h1 .ja02 {
  background-color: #f4e3ef;
  border-radius: 5px;
  padding: 28px;
  display: table;
}

.mainWrap .mainText h1 .ja01 {
  margin-left: 15px;
}

.mainArea img {
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .mainArea img {
    height: 60vh !important;
  }
}

.subArea {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.subArea::before {
  content: url(../images/home/home_greeting_deco01.png);
  position: absolute;
  top: 7%;
  right: 15%;
  animation: 3s fuwafuwa infinite;
  z-index: 1;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .subArea::before {
    right: 1%;
  }
}

@media screen and (max-width: 834px) {
  .subArea::before {
    display: none;
  }
}

.subArea::after {
  content: url(../images/home/home_greeting_deco02.png);
  position: absolute;
  top: 7%;
  left: 15%;
  animation: 5s fuwafuwa2 infinite;
  visibility: inherit !important;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .subArea::after {
    left: 1%;
  }
}

@media screen and (max-width: 834px) {
  .subArea::after {
    display: none;
  }
}

.subArea h1 {
  padding: 5rem 24px;
  font-size: clamp(40px, 5vw, 50px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  z-index: 1;
  position: relative;
}

.subArea h1 .en {
  font-size: clamp(20px, 2vw, 24px);
  display: block;
  line-height: 1.5em;
  color: #84004f;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #111111;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  background-color: #fcf7fb;
}

@media only screen and (max-width: 900px) {
  footer#global_footer {
    text-align: center;
  }
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
  padding: 100px 24px;
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer {
    display: block;
  }
}

footer#global_footer .footer::before {
  content: url(../images/home/home_footer_deco.png);
  position: absolute;
  left: 0;
  top: -24px;
  right: 0;
}

footer#global_footer .footer .ftLogo {
  text-align: left;
  margin-bottom: 40px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .ftLogo {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ftLogo {
    text-align: center;
  }
}

footer#global_footer .footer .footerLeft address {
  text-align: left;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .footerLeft address {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .footerLeft address {
    text-align: center;
  }
}

footer#global_footer .footer .footrRight .footer_contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 70px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .footrRight .footer_contact {
    justify-content: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .footrRight .footer_contact {
    justify-content: center;
    margin-top: 40px;
    display: block;
  }
}

footer#global_footer .footer .footrRight .footer_contact a {
  color: #111111;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  background-color: #fff;
  font-size: clamp(24px, 2vw, 24px);
  line-height: 1em;
  padding: 24px;
  border-radius: 0 50px 50px 0;
  align-self: flex-end;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 640px) {
  footer#global_footer .footer .footrRight .footer_contact a {
    width: 100%;
    display: inline-block;
  }
}

footer#global_footer .footer .footrRight .footer_contact a img {
  position: absolute;
  left: -80px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 640px) {
  footer#global_footer .footer .footrRight .footer_contact a img {
    left: 0;
  }
}

footer#global_footer .footer .footrRight .footer_contact .footer_mail {
  font-size: clamp(20px, 2vw, 20px);
  margin-left: 130px;
}

@media screen and (max-width: 640px) {
  footer#global_footer .footer .footrRight .footer_contact .footer_mail {
    margin-left: 0;
    margin-top: 50px;
  }
}

footer#global_footer .footerTop .gmap iframe {
  width: 100%;
}

footer#global_footer .ftNav {
  display: flex;
  flex-flow: column wrap;
  height: 82px;
  width: 752px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .ftNav {
    margin: auto;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav {
    width: fit-content;
    margin: auto;
    height: auto;
  }
}

footer#global_footer .ftNav li {
  text-align: left;
}

footer#global_footer .ftNav li a {
  font-family: 'Zen Maru Gothic', serif;
  display: inline-block;
  color: #111111;
  font-weight: bold;
}

footer#global_footer #copy {
  color: #111111 !important;
  font-size: 1.4rem;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
}

footer#global_footer #copy a {
  font-weight: bold;
  text-decoration: none;
  color: #111111 !important;
}

.title_01 h2 {
  font-size: clamp(32px, 4vw, 48px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
}

.title_01 h2 .en {
  font-size: 1.4rem;
  color: #bf277e;
  display: block;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.title_deco h2 {
  position: relative;
  display: inline-block;
}

.title_deco h2::before {
  content: url(../images/home/home_title_deco_01.png);
  position: absolute;
  right: -60px;
  bottom: -70px;
  z-index: -1;
}

.title_deco2 h2 {
  position: relative;
  display: inline-block;
}

.title_deco2 h2::before {
  content: url(../images/home/home_title_deco_02.png);
  position: absolute;
  right: -50px;
  bottom: -40px;
  z-index: -1;
}

.title_deco3 h2 {
  position: relative;
  display: inline-block;
}

.title_deco3 h2::before {
  content: url(../images/home/home_title_deco_03.png);
  position: absolute;
  right: -60px;
  bottom: -20px;
  z-index: -1;
}

.news {
  max-height: 290px;
  overflow-y: auto;
  text-align: left;
}

.news dt,
.news dd {
  border: none !important;
}

@media screen and (max-width: 834px) {
  .news dt,
  .news dd {
    width: 100% !important;
  }
}

.news dt {
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.news dl {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

@media screen and (max-width: 834px) {
  .news dl {
    display: block !important;
  }
}

.text div {
  line-height: 2.5em;
}

.text div .talR {
  text-align: right !important;
  width: 100%;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  font-size: 2.4rem;
}

.img img {
  border-radius: 8px;
}

.cont_01 {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.cont_01 .text,
.cont_01 .title_01,
.cont_01 .btn {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .cont_01 .img img {
    filter: opacity(0.3);
  }
}

@media only screen and (max-width: 900px) {
  .cont_01 .img img {
    filter: none;
  }
}

.cont_01 .img img {
  clip-path: circle(50% at center);
}

.cont_01 .img01 {
  position: absolute;
  left: 100px;
  top: 100px;
}

@media only screen and (max-width: 900px) {
  .cont_01 .img01 {
    position: inherit;
    left: unset;
    top: unset;
    margin-bottom: 30px;
  }
}

.cont_01 .img02 {
  position: absolute;
  right: 100px;
  bottom: 100px;
}

@media only screen and (max-width: 900px) {
  .cont_01 .img02 {
    position: inherit;
    right: unset;
    bottom: unset;
    margin-top: 30px;
  }
}

.cont_01::before {
  content: url(../images/home/home_greeting_deco01.png);
  position: absolute;
  top: 0;
  right: 15%;
  animation: 3s fuwafuwa infinite;
  z-index: 1;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .cont_01::before {
    right: 1%;
  }
}

@media only screen and (max-width: 900px) {
  .cont_01::before {
    right: 1%;
  }
}

.cont_01::after {
  content: url(../images/home/home_greeting_deco02.png);
  position: absolute;
  bottom: 0;
  left: 15%;
  animation: 5s fuwafuwa2 infinite;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .cont_01::after {
    left: 1%;
  }
}

@media only screen and (max-width: 900px) {
  .cont_01::after {
    left: 1%;
  }
}

@media screen and (max-width: 640px) {
  .cont_01::after {
    bottom: -8%;
  }
}

.cont_02 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 40px;
}

@media only screen and (max-width: 900px) {
  .cont_02 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .cont_02 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.cont_03 {
  position: relative;
  z-index: 0;
}

.cont_03::before {
  content: url(../images/home/home_news_deco_01.png);
  position: absolute;
  left: 0;
  top: -24px;
}

.cont_03::after {
  content: url(../images/home/home_news_deco_02.png);
  position: absolute;
  right: 0;
  bottom: -55px;
}

.cont_03 .inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cont_03 .inner .title_01 {
  width: 30%;
}

@media only screen and (max-width: 900px) {
  .cont_03 .inner .title_01 {
    width: 100%;
  }
}

.cont_03 .inner .news {
  width: 68%;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .cont_03 .inner .news {
    width: 100%;
  }
}

.cont_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  padding-left: 5%;
}

@media screen and (max-width: 834px) {
  .cont_04 {
    display: block;
  }
}

.cont_04 .flxL {
  width: 30%;
}

.cont_04 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_04 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_04 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_04 .flxR {
  width: 68%;
}

@media screen and (max-width: 834px) {
  .cont_04 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media screen and (max-width: 834px) {
  .cont_04 {
    padding: 0 24px;
  }
}

.cont_04::before {
  content: url(../images/home/home_intro_bg.png);
  position: absolute;
  left: 0;
  bottom: -70px;
  border-radius: 0 15px 15px 0;
  z-index: -1;
}

.cont_04 .flxR {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 30px;
}

@media screen and (max-width: 640px) {
  .cont_04 .flxR {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* 回転するアニメーション */
@keyframes fuwafuwa {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fuwafuwa2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}

.flex_item_01, .flex_item_02, .flex_item_03 {
  position: relative;
}

@media screen and (max-width: 640px) {
  .flex_item_01, .flex_item_02, .flex_item_03 {
    margin-bottom: 50px;
  }
}

.flex_item_01 .box, .flex_item_02 .box, .flex_item_03 .box {
  color: #fff;
  width: 100%;
}

.flex_item_01 .box img, .flex_item_02 .box img, .flex_item_03 .box img {
  clip-path: circle(50% at center);
}

.flex_item_01 .box h3, .flex_item_02 .box h3, .flex_item_03 .box h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  text-align: center;
  margin-bottom: 30px;
}

.flex_item_01 .box:first-of-type, .flex_item_02 .box:first-of-type, .flex_item_03 .box:first-of-type {
  position: absolute;
  left: -50px;
  top: -20px;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .flex_item_01 .box:first-of-type, .flex_item_02 .box:first-of-type, .flex_item_03 .box:first-of-type {
    top: -70px;
    left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .flex_item_01 .box:first-of-type, .flex_item_02 .box:first-of-type, .flex_item_03 .box:first-of-type {
    top: -70px;
    left: 0;
  }
}

.flex_item_01 .box:nth-of-type(2) img, .flex_item_02 .box:nth-of-type(2) img, .flex_item_03 .box:nth-of-type(2) img {
  width: 100% !important;
}

.flex_item_01 .box:last-of-type, .flex_item_02 .box:last-of-type, .flex_item_03 .box:last-of-type {
  margin-top: -48%;
  background: url(../images/home/home_strengths_bg_01.png);
  border-radius: 0 0 15px 15px;
  padding: 200px 30px 30px 30px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .flex_item_01 .box:last-of-type, .flex_item_02 .box:last-of-type, .flex_item_03 .box:last-of-type {
    padding: 150px 24px 30px 24px;
  }
}

@media only screen and (max-width: 900px) {
  .flex_item_01 .box:last-of-type, .flex_item_02 .box:last-of-type, .flex_item_03 .box:last-of-type {
    padding: 200px 24px 30px 24px;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_item_01 .box:last-of-type, .flex_item_02 .box:last-of-type, .flex_item_03 .box:last-of-type {
    padding: 180px 24px 30px 24px;
  }
}

@media screen and (max-width: 640px) {
  .flex_item_01 .box:last-of-type, .flex_item_02 .box:last-of-type, .flex_item_03 .box:last-of-type {
    padding: 230px 24px 30px 24px;
  }
}

.flex_item_02 .box:last-of-type {
  background: url(../images/home/home_strengths_bg_02.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 900px) {
  .flex_item_03 {
    margin-top: 50px;
  }
}

.flex_item_03 .box:last-of-type {
  background: url(../images/home/home_strengths_bg_03.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 640px) {
  .flex_item_04 {
    box-shadow: 0 0 14px rgba(224, 124, 167, 0.4);
    border-radius: 15px;
  }
}

.flex_item_04 .box {
  width: 100%;
  text-align: center;
}

.flex_item_04 .box:first-of-type {
  z-index: 1;
}

.flex_item_04 .box:first-of-type img {
  border-radius: 15px;
}

.flex_item_04 .box:last-of-type {
  padding: 70px 24px 40px;
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  margin-top: -30px;
}

.flex_bnr {
  justify-content: space-between;
}

.flex_bnr .box {
  max-width: 280px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .flex_bnr .box {
    width: 48% !important;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_bnr .box {
    width: 48% !important;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .flex_bnr .box {
    width: 100% !important;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.flex_bnr .box a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  position: relative;
  font-size: clamp(24px, 2vw, 24px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  color: #111111;
  padding: 42px 10px;
}

.flex_bnr .box a::before {
  margin-right: 10px;
}

.flex_bnr .box:first-of-type a {
  background-color: #ffe8f2;
}

.flex_bnr .box:first-of-type a::before {
  content: url(../images/home/home_bnr_icon_01.png);
}

.flex_bnr .box:nth-of-type(2) a {
  background-color: #fdf5da;
}

.flex_bnr .box:nth-of-type(2) a::before {
  content: url(../images/home/home_bnr_icon_02.png);
}

.flex_bnr .box:nth-of-type(3) a {
  font-size: clamp(20px, 2vw, 20px);
  background-color: #f3e6f2;
}

.flex_bnr .box:nth-of-type(3) a::before {
  content: url(../images/home/home_bnr_icon_03.png);
}

.flex_bnr .box:last-of-type a {
  background-color: #f9e8ed;
}

.flex_bnr .box:last-of-type a::before {
  content: url(../images/home/home_bnr_icon_04.png);
}

.flex_bnr_02 {
  justify-content: space-between;
  gap: 30px;
}

.flex_bnr_02 .box {
  width: calc((100% - 30px) / 2);
}

@media screen and (max-width: 640px) {
  .flex_bnr_02 .box {
    width: 100%;
  }
}

.gmap iframe {
  width: 100%;
  vertical-align: bottom;
  border-radius: 8px;
}

.top-sns .sns_text {
  display: none;
}

.top-sns li {
  margin-right: 10px;
}

.btn a,
.btn2 a {
  /*線の基点とするためrelativeを指定*/
  position: relative;
  /*ボタンの形状*/
  color: #fff;
  padding: 28px;
  display: inline-block;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  min-width: 285px;
  background-color: #ff33a0;
  border-radius: 50px;
  line-height: 1em;
  margin: auto;
}

.btn a:hover,
.btn2 a:hover {
  background-color: #84004f;
}

@media screen and (max-width: 640px) {
  .btn a,
  .btn2 a {
    min-width: 100%;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

/* アニメーションスタートの遅延時間を決めるCSS*/
.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time04 {
  animation-delay: 0.4s;
}

.delay-time06 {
  animation-delay: 0.6s;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form div.select {
  min-width: 230px !important;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view p.price {
  font-size: 3rem;
}

div.item_view > ul li a {
  margin-bottom: 10px;
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.items {
  justify-content: space-between;
  margin-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .items {
    display: block !important;
  }
}

.items article {
  width: 32% !important;
  margin-left: 0 !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .items article {
    width: 48% !important;
  }
}

@media screen and (max-width: 640px) {
  .items article {
    width: 100% !important;
  }
}

.items article .photo {
  width: 100% !important;
}

.items article .name {
  color: #111111 !important;
}

.items article .price {
  color: #111111 !important;
}

.items article button {
  border-radius: 0 !important;
  background: #ccc !important;
  color: #fff !important;
  border: none !important;
  text-indent: 0 !important;
}

.shopPage div.items > article .thumb {
  z-index: 2;
  position: relative;
}

.shopPage div.items > article .hoverImg {
  position: absolute;
  top: 0;
  left: 0;
}

.shopPage div.items > article a.photo {
  position: relative;
}

.shopPage div.items > article a {
  text-decoration: none;
}

.shopPage div.items > article a.photo {
  width: 100%;
  height: auto;
  background-image: none !important;
}

.shopPage div.items > article a.photo img {
  display: block;
  width: 100% !important;
  height: 264px !important;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

#main #col_side1 {
  display: none;
}

.shopPage #main #col_side1 {
  display: block;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .shopPage #main #col_side1 {
    width: 100% !important;
  }
}

.shopPage #col_main {
  width: calc(100% - 300px) !important;
}

@media screen and (max-width: 834px) {
  .shopPage #col_main {
    width: 100% !important;
  }
}

.view_cart a {
  background-color: #ff33a0;
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.view_cart a i {
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 {
  border-radius: 8px;
}

.local_title_01 h2 {
  font-size: clamp(32px, 4vw, 48px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
}

.local_title_02 {
  border-radius: 8px;
}

.local_title_02 h2 {
  font-size: clamp(32px, 3vw, 36px);
  line-height: 1em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  position: relative;
  padding: 20px 20px 20px 7rem;
}

.local_title_02 h2::before {
  content: url(../images/home/home_bnr_icon_01.png);
  color: #ff33a0;
  font-size: 3rem;
  position: absolute;
  left: 1%;
  top: 50%;
  transform: translateY(-50%);
  height: 39px;
}

.sub_title_01 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  color: #84004f;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL, .img .imgR, .img .imgL {
    max-width: 100% !important;
  }
}

.local_cont_01 {
  background-size: cover;
}

.local_cont_01 .inner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner {
    display: block;
  }
}

.local_cont_01 .inner .flxL {
  width: 30%;
}

.local_cont_01 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .inner .flxR {
  width: 68%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_02 .inner {
  max-width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner {
    display: block;
  }
}

.local_cont_02 .inner .flxL {
  width: 38%;
}

.local_cont_02 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_02 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_02 .inner .flxR {
  width: 58%;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.flex_rinen .box {
  width: 92%;
  border-radius: 15px;
  margin-bottom: 80px;
  padding: 30px;
  color: #fff;
  position: relative;
  overflow: inherit !important;
  margin-left: auto;
}

.flex_rinen .box img {
  position: absolute;
  top: -80px;
  left: -90px;
  z-index: -1;
}

.flex_rinen .box h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  text-align: center;
  margin-bottom: 30px;
}

.flex_rinen .box article > div {
  text-align: center;
}

@media screen and (max-width: 640px) {
  .flex_rinen .box article > div {
    text-align: left;
  }
}

.flex_rinen .box:first-of-type {
  background: #84004f;
}

.flex_rinen .box:nth-of-type(2) {
  background: #F62A78;
}

.flex_rinen .box:last-of-type {
  background: #e07ca7;
}

.list_day {
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 1400px) {
  .list_day {
    max-width: calc(100% - 50px);
    margin-left: auto;
  }
}

@media screen and (max-width: 834px) {
  .list_day {
    max-width: calc(100% - 50px);
    margin-left: auto;
  }
}

.list_day::before {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background-color: #e07ca7;
  border-radius: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.list_day li {
  position: relative;
  margin-bottom: 130px;
  padding-left: 10%;
  overflow: inherit !important;
}

@media screen and (max-width: 834px) {
  .list_day li {
    padding-left: 15%;
  }
}

.list_day li img {
  border-radius: 8px;
}

.list_day li .imgWrap {
  display: flex;
  flex-wrap: wrap;
}

.list_day li .imgWrap img {
  width: 50% !important;
}

.list_day li h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  color: #84004f;
}

.list_day li .time {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
  border-top: solid 10px white;
  border-bottom: solid 10px white;
  background: url(../images/home/hm_bg.png);
  background-size: cover;
  font-size: clamp(24px, 2vw, 24px);
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  width: 100px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.flex_event {
  justify-content: space-between;
}

.flex_event .box {
  background-color: #fcf7fb;
  width: calc((100% - 60px) / 3);
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 15px;
}

@media screen and (max-width: 640px) {
  .flex_event .box {
    width: calc((100% - 30px) / 2);
    padding: 15px;
  }
}

.flex_event .box img {
  border-radius: 8px;
}

.flex_event .box h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.5em;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  text-align: center;
}

.dl_01 dt {
  border: none !important;
  padding: 25px !important;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  color: #84004f;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  padding: 25px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

.dl_01 dl {
  border-bottom: 1px solid #e07ca74a;
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.list_btn {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media only screen and (max-width: 900px) {
  .list_btn {
    position: inherit;
  }
}

.list_btn ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

@media screen and (max-width: 640px) {
  .list_btn ul {
    grid-template-columns: 1fr;
  }
}

.list_btn li a {
  text-align: center;
  display: block;
  background-color: #ff33a0;
  color: #fff;
  font-weight: bold;
  font-family: 'Zen Maru Gothic', serif;
  padding: 20px;
  border-radius: 8px;
}

.contTel a {
  font-size: clamp(32px, 4vw, 48px);
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #111111 !important;
}

.contTel a img {
  margin-right: 8px;
}

.qa dd, .qa dt {
  border: none !important;
  width: 100% !important;
  line-height: 2.25em;
}

.qa dt {
  position: relative;
  padding: 20px 35px 20px 5.8rem !important;
  background-color: #f7f7f7 !important;
  border-radius: 8px;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  font-size: 2rem;
}

.qa dt::before {
  content: "Q";
  font-size: 3rem;
  color: #ff33a0;
  font-family: 'Zen Maru Gothic', serif;
  font-weight: bold;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.qa dt::after {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  position: absolute;
  font-size: 14px;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qa dd {
  padding: 20px 35px 20px 5.5rem !important;
  position: relative;
}

.qa dl {
  display: block !important;
  margin-bottom: 20px;
}

.qa dl:last-of-type {
  margin-bottom: 0;
}

.form {
  border-radius: 8px;
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #e7e7e7 !important;
  padding: 15px 0;
}

.form dl.file dt {
  background-color: #f7f7f7 !important;
}

div.error {
  margin-top: 200px !important;
}

.policy_cont p {
  margin-bottom: 10px;
}

.policy_cont h2 {
  font-size: 1.2em;
  border-bottom: 1px solid #ff33a0;
  padding-bottom: 8px;
  font-weight: bold;
  margin: 50px 0 20px 0;
  font-family: 'Zen Maru Gothic', serif;
}

.policy_cont02 {
  max-height: 250px;
  overflow-y: auto;
}

.policy_cont02 p {
  margin-bottom: 10px;
}

.policy_cont02 h2 {
  font-size: 1.2em;
  border-bottom: 1px solid #ff33a0;
  padding-bottom: 8px;
  font-weight: bold;
  margin: 50px 0 20px 0;
  font-family: 'Zen Maru Gothic', serif;
}

.title {
  display: none;
}

.confirm {
  margin-top: 230px !important;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 280px !important;
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeupTrigger {
  opacity: 0;
}

.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
